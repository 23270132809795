import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'
import loadable from '@loadable/component'
import { useLocation } from '@reach/router'

import media from '../../components/css/media'

import Layout from '../../components/common/layout'
import Seo from '../../components/common/seo'
import { GameTilePreloader } from './gameTilePreloader'
import { getCasinoToken } from '../../adapters/user'
import { AuthContext } from '../../context/authProvider'

import { getBannerByType } from '../../dataManipulation/banners'
import storageService from '../../services/storageService'

const Container = loadable(() => import('../../components/common/container'))
const Carousel = loadable(() => import('../../components/common/carousel'))
const CasinoMenu = loadable(() => import('./casinoMenu'))
const GameCategoryRow = loadable(() => import('./gameCategoryRow'))
const GameTile = loadable(() => import('./gameTile'))

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${media.desktop`
    flex-flow: row;
  `};
`

const SideMenu = styled.aside`
  ${media.desktop`
    margin: 1rem 0;
    flex-direction: column;
    flex: 0 0 20%;
  `};
`

const ContentArea = styled.div`
  flex: 0 0 100%;
  padding: 1.8rem 0;
  overflow: hidden;

  ${media.desktop`
    flex: 0 0 80%;
  `};
`

const CasinoCategoryContainer = styled.section`
  .container {
    padding: 0 0 0 1rem;
  }

  ${media.desktop`
    .container {
      padding: 0 1rem;
    }
  `};
`

const CasinoPage = ({ pageContext: { title, description, type, slug } }) => {
  const intl = useIntl()
  const location = useLocation()
  const { isLoggedIn } = useContext(AuthContext)

  const [oryxToken, setOryxToken] = useState(null)

  const data = useStaticQuery(graphql`
    {
      casinoMenuApi {
        values {
          casinoMenu {
            type
            slug
            name
            is_visible
            games {
              desktopSlug
              mobileSlug
              title
              gametags {
                gametag_daily_jackpot
                gametag_hot
                gametag_new
                gametag_power_hour
                gametag_power_week
                gametag_vault_boost
              }
              slug
              thumbnail {
                alt
                url
                sizes {
                  medium
                }
              }
            }
          }
          lang
        }
      }
      bannersApi {
        values {
          lang
          banners {
            activeFrom
            activeFromStr
            activeTo
            activeToStr
            backgroundColor
            content
            vertical
            ctaLoggedIn {
              target
              title
              url
            }
            ctaLoggedOut {
              target
              title
              url
            }
            imageBackground {
              url
              optimized {
                src
              }
            }
            imageBackgroundMobile {
              url
              optimized {
                src
              }
            }
          }
        }
      }
    }
  `)

  const {
    bannersApi: { values },
    casinoMenuApi: { values: casinoMenu },
  } = data

  const dataExistForLocale = values.some((row) => row.lang === intl.locale)
  const dataExistForPage =
    dataExistForLocale &&
    values.filter((row) => row.lang === intl.locale)[0].banners
  const banners = dataExistForPage
    ? values.filter((row) => row.lang === intl.locale)[0].banners
    : []

  const menuExistForLocale = casinoMenu.some((row) => row.lang === intl.locale)
  const menuExistForPage =
    menuExistForLocale &&
    casinoMenu.filter((row) => row.lang === intl.locale)[0].casinoMenu
  const menu = menuExistForPage
    ? casinoMenu
        .filter((row) => row.lang === intl.locale)[0]
        .casinoMenu.filter((row) => row.type === type)
    : []

  useEffect(() => {
    const fetchToken = async () => {
      const response = await getCasinoToken()
      if (response.ok) {
        setOryxToken(response.data.data)
      }
    }

    if (isLoggedIn && !oryxToken) {
      fetchToken()
    }
  }, [oryxToken, isLoggedIn])

  useEffect(() => {
    storageService.setValue('lobby', location.pathname)
  })

  const getGames = (games) => {
    return games.map((game, i) => (
      <GameTile
        key={i}
        game={game}
        token={oryxToken}
        fallback={<GameTilePreloader />}
      />
    ))
  }

  return (
    <Layout>
      <Seo
        title={intl.formatMessage({ id: `${title}` })}
        description={intl.formatMessage({ id: `${description}` })}
      />
      <Carousel slides={getBannerByType(banners, intl.locale, type)} />
      <CasinoCategoryContainer>
        <Container>
          <ContentWrapper>
            <SideMenu>
              <CasinoMenu menu={menu} slug={slug} />
            </SideMenu>
            <ContentArea>
              {menu.map(
                (m, i) =>
                  getGames(m.games.slice(0, 20)).length > 0 && (
                    <GameCategoryRow
                      key={i}
                      name={m.name}
                      slug={m.slug}
                      games={getGames(m.games)}
                    />
                  )
              )}
            </ContentArea>
          </ContentWrapper>
        </Container>
      </CasinoCategoryContainer>
    </Layout>
  )
}

export default CasinoPage
